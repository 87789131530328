import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiHelper } from '../../helpers/api.helper';
import { catchError, EMPTY, map, of, tap, throwError } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticateService } from '../../services';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private notification: NzNotificationService,
    private cookieService: CookieService,
    private authenticateService: AuthenticateService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    let optionHttpRequest: {
      headers?: HttpHeaders;
      url?: string;
    } = {
      url: ApiHelper.host(`/api/v1/${req.url}`),
    }

    //-- setup header
    if (
      !this.isWithOutHeaderForRoute(req) &&
      this.cookieService.check('cross_origin') &&
      this.cookieService.check('id_token')
    ) {
      optionHttpRequest = this.setupHeadersAuthentication(req);
    }

    const clone = req.clone(optionHttpRequest);

    return next.handle(clone).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const responseCode = Number(event.body?.meta?.response_code || 10000);
          if(responseCode === 10000) {
            this.cookieService.deleteAll();
            this.router.navigate(['/authenticate']);
          }
          
          if (responseCode > 10000 && responseCode < 19990) {
            if(responseCode === 19000) {
              this.router.navigate(['/authenticate/department']);
            } else {
              this.router.navigate(['/authenticate/error'], {
                queryParams: {
                  code: responseCode
                }
              });
            }
          }
        } 
      }),
      catchError((err) => {
        this.notification.error('Server Error: ' + err.name, err.message);
        return EMPTY;
      })
    );
  }

  isWithOutHeaderForRoute(req: HttpRequest<any>): boolean {
    return (
      req.urlWithParams.includes('authentication') ||
      req.urlWithParams.includes('verify-code') ||
      req.urlWithParams.includes('logout') ||
      req.urlWithParams.includes('mock-okta')
    );
  }

  setupHeadersAuthentication(req: HttpRequest<any>) {
    const id_token = this.cookieService.get('id_token');

    const header = {
      'Authorization': `Bearer ${id_token}`,
      'App-payload': JSON.stringify({
        cross_origin: this.cookieService.get('cross_origin'),
        access_token: this.cookieService.get('access_token'),
        id_token: id_token,
        refresh_token: this.cookieService.get('refresh_token'),
        session_expire: this.cookieService.get('session_expire')
      })
    }

    if (req.params.get('isFormData') === 'YES') { }
    else {
      Object.assign(header, {
        'Content-Type': 'application/json',
      });
    }

    return {
      url: ApiHelper.host(`/api/v1/${req.url}`),
      headers: new HttpHeaders(header)
    }
  }
}
