import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { setTheme } from 'ngx-bootstrap/utils';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AuthenticateService } from './services';
import { State } from './stores/reducers';
import { authenticateAction, actionLoadConfig, actionLoadMedia, actionSetupMediaRoot, actionSetupDirectoryMedia, actionRolesAccess } from './stores/actions';
import { UploadService } from './services/upload/upload.service';
import { MediaService } from './services/media/media.service';
import { UserRoleService } from './services/user-role/user-role.service';
import { LoadingComponent } from './components/loading/loading.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    NzIconModule,
    NzLayoutModule,
    NzMenuModule,
    AlertModule,
    LoadingComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [BsModalService]
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authenticateService: AuthenticateService,
    private uploadService: UploadService,
    private mediaService: MediaService,
    private userRoleService: UserRoleService,

    private store: Store<State>,
    private router: Router
  ) {
    setTheme('bs5');
  }

  isLoading: boolean = true;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      Promise.all([
        this.setStoreUserProfile(),
        this.setLoadUploadConfig(),
        this.setLoadMedia(),
        this.setUserRolesAccess()
      ]).then(([userProfile]) => {
        this.isLoading = false;
      });
    }
  }

  async setStoreUserProfile() {
    const isTimeSession = this.isCheckSession();
    if(isTimeSession) {
      const data = await firstValueFrom(this.authenticateService.getUserProfile());
      this.store.dispatch(authenticateAction.setLoginProfileInStore({ payload : { loginProfile: data } }));
    }
  }

  async setLoadUploadConfig() {
    const isTimeSession = this.isCheckSession();
    if(isTimeSession) {
      const data = await firstValueFrom(this.uploadService.getConfiguration());
      this.store.dispatch(actionLoadConfig(data));
    }
  }

  async setLoadMedia() {
    const isTimeSession = this.isCheckSession();
    if(isTimeSession) {
      const data = await firstValueFrom(this.mediaService.getMedia());
      const root = await firstValueFrom(this.mediaService.fetchRootDirectory());

      const responseCode = Number(root.meta?.response_code || 0);
      if(responseCode === 20000) {
        this.store.dispatch(actionSetupMediaRoot({ root: root.data }));
      }
    }
  }

  async setUserRolesAccess() { 
    const isTimeSession = this.isCheckSession();
    if(isTimeSession) {
      const res = await firstValueFrom(this.userRoleService.fetchUserRolesAccess());
      const responseCode = Number(res.meta?.response_code || 0);
      const rolesAccess = res?.data as any;
      if(responseCode === 20000 && rolesAccess) {
        this.store.dispatch(actionRolesAccess({
          isSuperUser: rolesAccess?.isSuperUser,
          permissions: rolesAccess?.permission
        }));
      }
    }
  }

  isCheckSession() {
    return this.authenticateService.checkTimeoutSessionExpire();
  }
}
