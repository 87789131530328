import { Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'authenticate',
    loadChildren: () =>
      import('./authenticate/authenticate.module').then((m) => m.AuthenticateModule),
  },
  {
    path: '**',
    component: ErrorComponent
  }
];
